import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Typography,
} from '@mui/material';
import {useSession} from "../../../../context/SessionContext";
import {Order, ActiveOrder} from "../../../../context/PageContext";
import OrderItem from "./OrderItem";

const OrderList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const { sessionId } = useSession();

    const [sampleOrderList, setSampleOrderList] = useState<Order[]>([]);
    const [activeOrderList, setActiveOrderList] = useState<ActiveOrder[]>([]);

    // Fetch the sample and active orders
    useEffect(() => {
        let continueToFetch = true;
        let intervalDuration = 1000; // Initial interval duration (1 second)
        let intervalId: NodeJS.Timeout | null = null;
        let timeoutId: NodeJS.Timeout | null = null;

        const fetchOrders = async () => {
            if (continueToFetch)
            {
                console.log("I am fetching...");

                try {
                    // Get the sample orders
                    let response = await fetch(`${process.env.REACT_APP_API_URL}/api/sample-orders/`);
                    if (!response.ok) throw new Error('Failed to fetch sample orders');
                    setSampleOrderList(await response.json());

                    // Get the active orders by session ID
                    response = await fetch(`${process.env.REACT_APP_API_URL}/api/active-orders/${sessionId}/`);
                    if (!response.ok) throw new Error('Failed to fetch active orders');
                    setActiveOrderList(await response.json());

                    setLoading(false);
                } catch (err: any) {
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        const startFetching = () => {
            if (intervalId) clearInterval(intervalId); // Clear the previous interval

            intervalId = setInterval(fetchOrders, intervalDuration);
        };

        const adjustInterval = () => {
            // Increase interval duration by 1 second every minute
            setTimeout(() => {
                intervalDuration += 1000; // Increase interval by 1 second
                startFetching(); // Restart interval with the new duration
                adjustInterval(); // Schedule the next adjustment
            }, 60000); // Run every 1 minute
        };

        const stopFetching = () => {
            continueToFetch = false;
            if (intervalId) clearInterval(intervalId); // Stop the fetching interval
            if (timeoutId) clearTimeout(timeoutId); // Stop any pending timeouts
        };

        // Start the fetching process
        startFetching();
        adjustInterval();

        // Stop fetching after 5 minutes
        timeoutId = setTimeout(() => {
            console.log("Stop fetching");
            stopFetching();
        }, 300000); // 5 minutes = 300,000 ms

        // Cleanup intervals and timeouts on unmount
        return () => {
            stopFetching();
        };
    }, [sessionId]);


    const getSampleOrderByOrderId = (orderId: number): Order | null => {
        // Find the sample order that matches the given order_id
        const sampleOrder = sampleOrderList.find(order => order.order_id === orderId);
        return sampleOrder || null; // Return null if not found
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <>
            {activeOrderList.map((activeOrder, index) => {
                const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);

                return (
                    <OrderItem activeOrder={activeOrder} sampleOrder={sampleOrder}/>
                );
            })}
            </>
        </Box>
    );
};

export default OrderList;
