import React, { createContext, useContext, useState, useEffect } from 'react';

export interface ElevatorOption {
    location_id: number;
    location: string;
}

export interface TrailerLoad {
    previous_loads_id: number;
    commodity: string;
}

export interface TrailerCleaning {
    trailer_cleaning_id: number;
    cleaning_method: string;
}

interface AppContextType {
    elevatorOptions: ElevatorOption[];
    trailerLoadOptions: TrailerLoad[];
    trailerCleaningOptions: TrailerCleaning[];
    loading: boolean;
    error: string | null;
}

const AppContext = createContext<AppContextType>({
    elevatorOptions: [],
    trailerLoadOptions: [],
    trailerCleaningOptions : [],
    loading: false,
    error: null,
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [elevatorOptions, setElevatorOptions] = useState<ElevatorOption[]>([]);
    const [trailerLoadOptions, setTrailerLoadOptions] = useState<TrailerLoad[]>([]);
    const [trailerCleaningOptions, setTrailerCleaningOptions] = useState<TrailerCleaning[]>([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchElevatorOptions = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/locations/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch elevator options.');
                }
                const data = await response.json();
                setElevatorOptions(data);
            } catch (err: any) {
                setError(err.message || 'An unexpected error occurred.');
            } finally {
                setLoading(false);
            }
        };

        const fetchPreviousLoads = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/previous-loads/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: TrailerLoad[] = await response.json();
                setTrailerLoadOptions(data);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchTrailerCleaningMethods = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/trailer-cleaning/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: TrailerCleaning[] = await response.json();
                setTrailerCleaningOptions(data);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchElevatorOptions();
        fetchPreviousLoads();
        fetchTrailerCleaningMethods();
    }, []);

    return (
        <AppContext.Provider value={{ elevatorOptions, trailerLoadOptions, trailerCleaningOptions, loading, error }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};
