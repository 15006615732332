import React from 'react';
import Header from "./components/Header/Header";
import OrderList from "./components/OrderList/OrderList";

interface LvGtiSimulationProps {
    handlePageChange: (changeTo: 'Login' | 'LV-GTI-SIMULATION') => void;
}

const LvGtiSimulator: React.FC<LvGtiSimulationProps> = ({ handlePageChange }) => {

    const handleLogout = () => {
        handlePageChange('Login');
    };

    return (
        <div className="bg-gray-50 h-full w-full">
            <Header handleLogout={handleLogout}/>
            <OrderList />
        </div>
    );
};

export default LvGtiSimulator;
