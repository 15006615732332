// src/components/LandingPage.tsx
import React from 'react';
import {Button, Container, Typography, Box, Card, CardContent, Link} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoSVG from "../components/Mobile/MobileLogo/LogoSVG";
import MobileAppSVG from "./MobileAppSVG";

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md" style={{textAlign: 'center', marginTop: '2rem'}}>
            <LogoSVG/>

            {/* Advertisement Section */}
            <Box mt={4} mb={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Proudly Serving Agriculture for Over 40 Years
                        </Typography>
                        <Typography variant="body1" style={{textAlign: 'justify', marginBottom: '16px'}}>
                            As Canada's leader in material handling automation, we have powered over 1,000 facilities
                            worldwide with tailored solutions.
                        </Typography>

                        <Typography variant="body1" style={{textAlign: 'justify', marginBottom: '16px'}}>
                            Our expertise in grain handling, seed cleaning, feed processing, and fertilizer blending
                            ensures efficient and reliable operations. We are your partners, committed to supporting
                            your systems with custom solutions and ongoing support.
                        </Typography>

                        <Typography variant="body1" style={{textAlign: 'justify', marginBottom: '16px'}}>
                            Discover how LV Control can power your facility forward, every step of the way.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            <Link href="https://www.lvcontrol.com/" target="_blank" rel="noopener">
                                www.lvcontrol.com
                            </Link>
                        </Typography>
                    </CardContent>
                </Card>
            </Box>


            {/* Title */}
            <Typography variant="h5" gutterBottom>
                LV Truck Management System Mobile Application Demo
            </Typography>

            {/* App QR */}
            <Box mt={4} mb={4} style={{width: '150px', margin: '0 auto', textAlign: 'center'}}>
                <Typography variant="h5" gutterBottom style={{color: '#d32f2f', fontWeight: 'bold'}}>
                    Scan Me
                </Typography>
                <MobileAppSVG/>
            </Box>

            {/* Link to the simulator page */}
            <Box mb={4}>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://lvtms.lvcontrol.com/lv-gti-simulator"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: '16px' }} // Add margin to the right
                >
                    Open LV GTI Simulator
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    href="https://lvtms.lvcontrol.com/lv-truck"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Open LV Truck
                </Button>
            </Box>

        </Container>
    );
};

export default LandingPage;