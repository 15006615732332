import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid2 as Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { usePage } from "../../context/PageContext";
import { useAppContext } from "../../context/AppContext";
import { createOrUpdateActiveOrder } from "../../api/api";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProbingScreen: React.FC = () => {
  const { selectedOrder, activeOrder, setActiveOrder, goToPage } = usePage();
  const { elevatorOptions } = useAppContext();

  const [probeStatus, setProbeStatus] = useState("Waiting...");
  const [buttonLabel, setButtonLabel] = useState("Ready for Probe");
  const [viewDialogue, setViewDialogue] = useState(false);

  const handleButtonClick = async () => {
    if (!selectedOrder || !activeOrder) {
      console.error("Error: Missing session, selected order, or active order.");
      return;
    }

    if (probeStatus === "Waiting...") {
      // Probe sampling
      createOrUpdateActiveOrder({
        ...activeOrder,
        probe_sampling: true,
      }).then((data) => {
        setActiveOrder(data.received_data);
      });

      // Simulate the probing process
      setProbeStatus("Sampling in Progress...");
      setTimeout(() => {
        // Probe sampling completed
        createOrUpdateActiveOrder({
          ...activeOrder,
          probe_sampling: true,
          probe_sampling_completed: true,
        }).then((data) => setActiveOrder(data.received_data));

        setProbeStatus("Sampling Completed!");
        setButtonLabel("Proceed to Driveway");
        setViewDialogue(true);
      }, 5000); // simulate 5 seconds delay for probing
    } else {
      goToPage("Dumping");
    }
  };

  // Render error messages for missing selectedOrder or activeOrder
  if (!selectedOrder) {
    return (
      <Card sx={{ margin: 2 }}>
        <CardContent>
          <Typography variant="h5" color="error" gutterBottom>
            Error: No order selected.
          </Typography>
          <Typography>
            Please go back and select an order before proceeding to check-in.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ margin: 2 }}>
      <CardContent>
        <LogoSVG />
        <Box mb={2}>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {selectedOrder.producer}
          </Typography>

          <Grid container spacing={1}>
            {/* Order */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Order:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                {`${selectedOrder.order_number} / ${selectedOrder.item_number}`}
              </Typography>
            </Grid>

            {/* Product */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Product:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{selectedOrder.product}</Typography>
            </Grid>

            {/* Delivery Elevator */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Delivery Elevator:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                {
                  elevatorOptions.find(
                    (option) => option.location_id === activeOrder?.location
                  )?.location
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginY: 5 }}>
            {/* Delivery Elevator */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Probe Status:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  color: "#28a745", // success green color
                  animation: "fadeInOut 2s ease-in-out infinite",
                  "@keyframes fadeInOut": {
                    "0%": { opacity: 0.5 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.5 },
                  },
                }}
              >
                {probeStatus}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Button
          fullWidth
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          disabled={probeStatus === "Sampling in Progress..."}
        >
          {buttonLabel}
        </Button>
      </CardContent>

      <Dialog
        open={viewDialogue}
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <div className="w-full flex text-sm items-center justify-end">
          <IconButton
            aria-label="close"
            onClick={() => {
              setViewDialogue(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <CheckCircleOutlineIcon color={"success"} />
            Sampling Complete
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={probeStatus === "Sampling in Progress..."}
          >
            {buttonLabel}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProbingScreen;
