import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Order, ActiveOrder} from "../../../../context/PageContext";
import {useAppContext} from "../../../../context/AppContext";
import OrderItemDetails from "./OrderItemDetails";

interface OrderAccordionItemProps {
    activeOrder: ActiveOrder;
    sampleOrder: Order | null;
}

const OrderAccordionItem: React.FC<OrderAccordionItemProps> = ({
                                                                   activeOrder,
                                                                   sampleOrder
                                                               }) =>
{
    const { elevatorOptions } = useAppContext();

    function getLocationById(locationId: number | null): string {
        const location = elevatorOptions.find(item => item.location_id === locationId);
        return location ? location.location : 'N/A';
    }

    // Determine the status message based on activeOrder state
    let statusMessage = 'Waiting for Check In...';
    let lvTruckLocation = "Check-In";

    if (activeOrder.completed) {
        statusMessage = 'Completed!';
        lvTruckLocation = "Completed";
    }
    else if (activeOrder.truck_at_ticket_print_station) {
        statusMessage = 'Ticket Printing';
        lvTruckLocation = "Ticket Print Station";
    }
    else if (activeOrder.dumping_completed) {
        statusMessage = 'Dumping completed!';
    }
    else if (activeOrder.scale_tare_weight) {
        statusMessage = 'Taking Tare Weight...';
    }
    else if (activeOrder.scale_unloading_truck) {
        statusMessage = 'Unloading Truck...';
    }
    else if (activeOrder.scale_gross_weight) {
        statusMessage = 'Taking Gross Weight...';
    }
    else if (activeOrder.on_scale) {
        statusMessage = 'Truck is on scale...';
        lvTruckLocation = "Driveway";
    }
    else if (activeOrder.probe_sampling_completed) {
        statusMessage = 'Probe Completed! Waiting for Truck on Scale...';
        lvTruckLocation = "Waiting for Driveway";
    }
    else if (activeOrder.probe_sampling) {
        statusMessage = 'Sampling in Progress...';
        lvTruckLocation = "Probe";
    }
    else if (activeOrder.ready_for_probe) {
        statusMessage = 'Waiting for Probe...';
    }

    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`panel-${activeOrder.order_id}-content`}
                id={`panel-${activeOrder.order_id}-header`}
            >
                <div className="grid grid-cols-1 sm:grid-cols-5 w-full gap-4 mr-6">
                    <Typography>Order {sampleOrder ? sampleOrder.order_number : "N/A"}</Typography>
                    {!expanded && (
                        <>
                            <Typography>Item Number {sampleOrder ? sampleOrder.item_number : "N/A"}</Typography>
                            <Typography>{getLocationById(activeOrder.location)}</Typography>
                            <Typography>Product {sampleOrder ? sampleOrder.product : "N/A"}</Typography>
                            <Typography>{statusMessage}</Typography>
                        </>
                    )}
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <OrderItemDetails
                    activeOrder={activeOrder}
                    sampleOrder={sampleOrder}
                    statusMessage={statusMessage}
                    lvTruckLocation={lvTruckLocation}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default OrderAccordionItem;