import React from 'react';
import {Order, ActiveOrder} from "../../../../context/PageContext";
import {useAppContext} from "../../../../context/AppContext";
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

interface OrderItemDetailsProps {
    activeOrder: ActiveOrder;
    sampleOrder: Order | null;
    statusMessage: string;
    lvTruckLocation: string;
}

const LabelInput: React.FC<{
    label: string;
    value: string;
    inputType?: "text" | "select";
    className?: string;
    id?: string;
    options?: { value: string; label: string }[];
}> = ({label, value, inputType = "text", className, id, options = []}) => (
    <div className={`flex flex-col sm:flex-row sm:items-center gap-1 ${className}`}>
        <label htmlFor={id} className="text-xs font-semibold text-gray-700 w-full sm:w-28">
            {label}
        </label>
        {inputType === "select" ? (
            <select
                id={id}
                value={value}
                className="border rounded-md p-0.5 text-xs font-semibold outline-none focus:ring-2 focus:ring-blue-500 w-full"
                disabled
            >
                {options.length > 0 ? (
                    options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))
                ) : (
                    <option value={value}>{value}</option>
                )}
            </select>
        ) : (
            <input
                id={id}
                type={inputType}
                value={value}
                className="border rounded-md p-0.5 text-xs font-semibold outline-none focus:ring-2 focus:ring-blue-500 w-full"
                readOnly
            />
        )}
    </div>
);

const OrderItemDetails: React.FC<OrderItemDetailsProps> = ({
                                                               activeOrder,
                                                               sampleOrder,
                                                               statusMessage,
                                                               lvTruckLocation
                                                           }) => {
    const {trailerLoadOptions, trailerCleaningOptions} = useAppContext();

    const getCommodityByLoadId = (loadId: number | null) => {
        const load = trailerLoadOptions.find(item => item.previous_loads_id === loadId);
        return load ? load.commodity : 'N/A';
    };

    const getCleaningMethodByCleaningId = (cleaningId: number | null) => {
        const load = trailerCleaningOptions.find(item => item.trailer_cleaning_id === cleaningId);
        return load ? load.cleaning_method : 'N/A';
    };

    // Example options for dropdowns
    const sourceOptions = ['None', 'Source1', 'Source2'];
    const destinationOptions = ['None', 'Destination1', 'Destination2'];

    return (
        <div className="p-2 space-y-4">
            {/* Grids Container */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-2">

                {/* First Grid - Compact */}
                <div className="flex flex-col space-y-1 col-span-1 lg:col-span-1">
                    <p className="text-xs font-semibold text-gray-700">Item
                        Number: {sampleOrder ? sampleOrder.item_number : 'N/A'}</p>
                    <p className="text-xs font-semibold text-gray-700">{new Date().toLocaleDateString()}</p>
                    <p className="text-green-600 font-semibold text-xs py-1">{statusMessage}</p>
                    <LabelInput label="Identifier:" value="-"/>
                </div>

                {/* Second Grid */}
                <div className="flex flex-col space-y-2 col-span-1 lg:col-span-3">
                    <div className="grid grid-cols-2 gap-2">
                        {/* Labels Column */}
                        <div className="flex flex-col space-y-2">
                            <LabelInput label="Job ID:" value="-" id="job-id"/>
                            <LabelInput label="Ticket ID:" value="-" id="ticket-id"/>
                            <LabelInput label="Source:" value="None" inputType="select"
                                        options={sourceOptions.map(src => ({value: src, label: src}))} id="source"/>
                            <LabelInput label="Destination:" value="None" inputType="select"
                                        options={destinationOptions.map(dest => ({value: dest, label: dest}))}
                                        id="destination"/>
                            <LabelInput label="" value="" id="additional"/>
                        </div>

                        {/* Inputs and Filters Column */}
                        <div className="flex flex-col space-y-2">
                            {/* Scale Input */}
                            <div className="flex items-center w-full">
                                <LabelInput label="Scale:" value="-" id="scale"/>
                            </div>

                            {/* Unloaded Input with Edit Icon */}
                            <div className="flex items-center w-full">
                                <LabelInput label="Unloaded:" value="N/A" id="unloaded"/>
                                <EditIcon className="text-xs cursor-pointer ml-1"/>
                            </div>

                            {/* Net Weight Input with Edit Icon */}
                            <div className="flex items-center w-full">
                                <LabelInput label="Net Weight:" value="N/A" id="net-weight"/>
                                <EditIcon className="text-xs cursor-pointer ml-1"/>
                            </div>

                            {/* Filters */}
                            <div className="flex flex-col space-y-1">
                                <label className="flex items-center gap-1 text-gray-700 text-xs font-semibold">
                                    <input type="checkbox" className="rounded" checked={true} readOnly/> Filter by Class
                                </label>
                                <label className="flex items-center gap-1 text-gray-700 text-xs font-semibold">
                                    <input type="checkbox" className="rounded" checked={true} readOnly/> Filter by
                                    Filling Plan
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Comments Textarea */}
                    <textarea
                        className="border rounded-md outline-none focus:ring-2 focus:ring-blue-500 w-full h-16 resize-none text-xs p-1 font-semibold"
                        placeholder="Comments"
                        readOnly
                        value=""
                    ></textarea>
                </div>

                {/* Third Grid */}
                <div className="flex flex-col space-y-2 col-span-1 lg:col-span-2">
                    <div className="grid grid-cols-1">
                        {/* Labels and Inputs */}
                        <div className="col-span-1 flex flex-col space-y-2">
                            {/* Carrier Dropdown with Search Icon */}
                            <div className="flex items-center space-x-1 w-full">
                                <LabelInput
                                    label="Carrier:"
                                    value="123 Transport"
                                    inputType="select"

                                    id="carrier"
                                />
                                <SearchIcon className="text-xs cursor-pointer"/>
                            </div>

                            {/* Shipper Ref */}
                            <LabelInput label="Shipper Ref:" value="-" id="shipper-ref"/>

                            {/* Producer Dropdown with Search Icon */}
                            <div className="flex items-center space-x-1 w-full">
                                <LabelInput
                                    label="Producer:"
                                    value="LVC Farms Ltd"
                                    inputType="select"

                                    id="producer"
                                />
                                <SearchIcon className="text-xs cursor-pointer"/>
                            </div>

                            {/* Truck Type Dropdown */}
                            <LabelInput
                                label="Truck Type:"
                                value="-"
                                inputType="select"
                                id="truck-type"
                            />

                            {/* Previous Loads Hauled - Bold Title */}
                            <p className="text-xs font-bold text-gray-700">Previous Loads Hauled:</p>

                            {/* Previous Loads Dropdowns */}
                            <div className="grid grid-cols-3 gap-2">
                                {[1, 2, 3].map((num) => (
                                    <div
                                        className={`flex flex-col sm:flex-row sm:items-center gap-1 ${!activeOrder[`previous_load_${num}` as keyof ActiveOrder] ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}>
                                        <label htmlFor={`previous-load-${num}`}
                                               className="text-xs font-semibold text-gray-700">
                                            {`${num}:`}
                                        </label>

                                        <select
                                            id={`previous-load-${num}`}
                                            value={getCommodityByLoadId(activeOrder[`previous_load_${num}` as keyof ActiveOrder] as number | null)}
                                            className="border rounded-md p-0.5 text-xs font-semibold outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                            disabled
                                        >
                                            <option
                                                value={getCommodityByLoadId(activeOrder[`previous_load_${num}` as keyof ActiveOrder] as number | null)}>
                                                {getCommodityByLoadId(activeOrder[`previous_load_${num}` as keyof ActiveOrder] as number | null)}
                                            </option>
                                        </select>
                                    </div>
                                ))}
                            </div>

                            {/* Trailer Cleaning */}
                            <div className="col-span-1 flex flex-col space-y-2">
                                <LabelInput
                                    label="Trailer Cleaning:"
                                    value={getCleaningMethodByCleaningId(activeOrder.trailer_cleaning)}
                                    inputType="select"
                                    id="trailer-cleaning"
                                    className={`${!activeOrder.trailer_cleaning ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fourth Grid - Compact */}
                <div className="flex flex-col space-y-2 col-span-1 lg:col-span-2">
                    {/* Class Dropdown */}
                    <LabelInput
                        label="Class:"
                        value="Wheat"
                        inputType="select"
                        id="class"
                    />

                    {/* Type Dropdown */}
                    <LabelInput
                        label="Type:"
                        value="Wheat"
                        inputType="select"
                        id="type"
                    />

                    {/* Grade Dropdown */}
                    <LabelInput
                        label="Grade:"
                        value={sampleOrder?.product ?? "N/A"}
                        inputType="select"
                        id="grade"
                    />

                    {/* Grade As Dropdown */}
                    <LabelInput
                        label="Grade As:"
                        value="1 CWRS 15.5"
                        inputType="select"
                        id="grade-as"
                    />

                    {/* Sample Box ID with Additional Vertical Padding */}
                    <LabelInput
                        label="Sample Box ID:"
                        value="None"
                        className="py-2" // Adds vertical padding
                        id="sample-box-id"
                    />

                    {/* Print Buttons with Print Icons and Centered Alignment */}
                    <div className="flex flex-col space-y-2 items-center">
                        <button
                            className="flex items-center justify-center px-3 py-1 border rounded-md bg-blue-500 text-white text-xs font-semibold"
                        >
                            <PrintIcon className="mr-1 text-sm"/>
                            Print Sample Label
                        </button>
                        <button
                            className="flex items-center justify-center px-3 py-1 border rounded-md bg-blue-500 text-white text-xs font-semibold"
                        >
                            <PrintIcon className="mr-1 text-sm"/>
                            Print Yard Slip
                        </button>
                    </div>
                </div>


                {/* Fifth Grid */}
                <div className="flex flex-col space-y-2 col-span-1 lg:col-span-3">

                    {/* First Box */}
                    <div className="border p-1 rounded-md">
                        <div className="grid grid-cols-2 gap-1">
                            <LabelInput
                                label="Dockage:"
                                value={activeOrder.probe_sampling_completed ? "0.5%" : "-"}
                                className={`${activeOrder.ready_for_probe && !activeOrder.probe_sampling_completed ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                            />
                            <LabelInput
                                label="Moisture:"
                                value={activeOrder.probe_sampling_completed ? "10.2%" : "-"}
                                className={`${activeOrder.ready_for_probe && !activeOrder.probe_sampling_completed ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                            />
                            <LabelInput
                                label="Protein:"
                                value={activeOrder.probe_sampling_completed ? "15.5%" : "-"}
                                className={`${activeOrder.ready_for_probe && !activeOrder.probe_sampling_completed ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                            />
                            <LabelInput label="Test Wgt:" value="-"/>
                            <LabelInput label="Ergot:" value="-"/>
                            <LabelInput label="Fusarium:" value="-"/>
                            <LabelInput
                                label="Frost:"
                                value="-"
                                inputType="select"
                                id="frost"
                            />
                            <LabelInput
                                label="Green:"
                                value="-"
                                inputType="select"
                                id="green"
                            />
                        </div>
                    </div>

                    {/* Second Box - LV Truck Title */}
                    <div className="relative border p-1 rounded-md">
                        <span className="absolute -top-2 left-4 bg-white px-1 text-xs font-semibold text-gray-700">LV Truck</span>
                        <LabelInput label="Location:" value={lvTruckLocation}/>
                        <div className="flex flex-row gap-1 mt-1 justify-center">
                            <button
                                className="flex items-center justify-center px-2 py-0.5 border rounded-md bg-green-500 text-white text-xs font-semibold"
                            >
                                <PrintIcon className="mr-1 text-sm"/>
                                Ticket Verify
                            </button>
                            <button
                                className="flex items-center justify-center px-2 py-0.5 border rounded-md bg-yellow-500 text-white text-xs font-semibold"
                            >
                                <PrintIcon className="mr-1 text-sm"/>
                                Ticket Hold
                            </button>
                        </div>
                    </div>
                </div>


            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-2 items-center">
                {/* First Button Group */}
                <div className="flex flex-wrap items-start gap-2">
                    {[
                        "Assign Nomination",
                        "Show Order Detail",
                        "Hide Scale",
                        "Go To Job",
                        "Copy From Ticket",
                    ].map((btn) => (
                        <button
                            key={btn}
                            className="px-2 py-0.5 border rounded-md bg-gray-100 hover:bg-gray-200 text-xs font-semibold"
                        >
                            {btn}
                        </button>
                    ))}
                </div>

                {/* Second Button Group */}
                <div className="flex flex-wrap items-end gap-2 ml-auto">
                    {[
                        "Accept",
                        "Tentative Reject",
                        "Permanent Reject",
                    ].map((btn) => (
                        <button
                            key={btn}
                            className="px-2 py-0.5 border rounded-md bg-gray-100 hover:bg-gray-200 text-xs font-semibold"
                        >
                            {btn}
                        </button>
                    ))}
                </div>
            </div>

            {/* Scales Section */}
            <div className="border p-1 rounded-md">
                <h2 className="text-xs font-semibold text-gray-800 mb-1">Scales</h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">

                    {/* Scale Units */}
                    <div>
                        {["TS01", "TS02"].map((scale) => (
                            <div key={scale}
                                 className="flex items-center justify-between p-0.5 bg-black text-green-500 font-semibold text-xs rounded-md">
                                <span>{scale}</span>
                                <span>M</span>
                                <span>0 kg</span>
                                <button
                                    className="bg-white text-black border rounded-md px-1 py-0.5 text-xs font-semibold">Zero
                                </button>
                            </div>
                        ))}
                    </div>

                    {/* Gross Weight */}
                    <div className="border p-1 rounded-md">
                        <h3 className="text-xs font-semibold text-gray-800 mb-1">Gross Weight</h3>
                        <LabelInput
                            label="Gross:"
                            value={activeOrder.scale_tare_weight ? "65,000 kg" : "-"}
                            className={`${activeOrder.scale_gross_weight && !activeOrder.scale_tare_weight ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                        />
                        <button
                            className="mt-0.5 px-2 py-0.5 border rounded-md bg-blue-500 text-white text-xs font-semibold">Manual
                            Gross Weight
                        </button>
                    </div>

                    {/* Tare Weight */}
                    <div className="border p-1 rounded-md">
                        <h3 className="text-xs font-semibold text-gray-800 mb-1">Tare Weight</h3>
                        <LabelInput
                            label="Tare:"
                            value={activeOrder.dumping_completed ? "20,000 kg" : "-"}
                            className={`${activeOrder.scale_tare_weight && !activeOrder.dumping_completed ? "ring-2 ring-green-500 animate-fadeInOut" : ""}`}
                        />
                        <button
                            className="mt-0.5 px-2 py-0.5 border rounded-md bg-blue-500 text-white text-xs font-semibold">Manual
                            Tare Weight
                        </button>
                    </div>

                </div>
            </div>

        </div>
    );

};

export default OrderItemDetails;
