import React from 'react';
import Box from '@mui/material/Box';
import logoSrc from "../../../assets/Logo/logo.svg";

const LogoDisplay: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
            }}
        >
            <img
                src={logoSrc}
                alt="LV CONTROL logo"
                style={{
                    width: '100%',
                    height: 'auto', // Maintain aspect ratio
                }}
            />
        </Box>
    );
}

export default LogoDisplay;
