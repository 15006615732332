import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box, Grid2 as Grid
} from '@mui/material';
import {usePage} from "../../context/PageContext";
import {useAppContext} from "../../context/AppContext";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";
import {createOrUpdateActiveOrder} from "../../api/api";

const TicketScreen: React.FC = () => {
    const { selectedOrder, activeOrder, setActiveOrder, goToPage } = usePage();
    const { elevatorOptions } = useAppContext();

    const ticketDetails = {
        Dockage: "0.5%",
        Moisture: "10.2%",
        Protein: "15.5%",
        TestWeight: "60.2 lb / bu",
        GrossWeight: "65,000 kg",
        TareWeight: "20,000 kg",
        UnloadWeight: "45,000 kg",
        NetWeight: "44,775 kg"
    };

    const handleOk = () => {
        // Ticket completed
        if (activeOrder)
        {
            createOrUpdateActiveOrder({
                ...activeOrder,
                completed: true
            }).then((data) => {
                setActiveOrder(data.received_data);
            });
        }

        goToPage('SelectOrder');
    };

    // Render error messages for missing selectedOrder or activeOrder
    if (!selectedOrder) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" color="error" gutterBottom>
                        Error: No order selected.
                    </Typography>
                    <Typography>Please go back and select an order before proceeding to check-in.</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card>
            <CardContent>
                <LogoSVG />
                <Box mb={2}>
                    <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>
                        {selectedOrder.producer}
                    </Typography>

                    <Grid container spacing={1}>
                        {/* Order */}
                        <Grid  size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Order:
                            </Typography>
                        </Grid>
                        <Grid  size={8}>
                            <Typography variant="body2">
                                {`${selectedOrder.order_number} / ${selectedOrder.item_number}`}
                            </Typography>
                        </Grid>

                        {/* Product */}
                        <Grid size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Product:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                {selectedOrder.product}
                            </Typography>
                        </Grid>

                        {/* Delivery Elevator */}
                        <Grid size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Delivery Elevator:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                {elevatorOptions.find(option => option.location_id === activeOrder?.location)?.location}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Delivery Summary
                    </Typography>

                    <Grid container spacing={1} ml={2}>
                        {/* Product */}
                        <Grid size={4}>
                            <Typography variant="body2">
                                Product:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                {selectedOrder.product}
                            </Typography>
                        </Grid>
                        <Grid size={4}>
                            <Typography variant="body2">
                                Graded As:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                1 CWRS 15.5
                            </Typography>
                        </Grid>
                    {Object.entries(ticketDetails).map(([key, value]) => (
                        <>
                            <Grid  size={4}>
                                <Typography variant="body2" key={key}>{`${key.replace(/([A-Z])/g, ' $1').trim()}:`}</Typography>
                            </Grid>
                            <Grid  size={8}>
                                <Typography variant="body2" key={key}>{`${value}`}</Typography>
                            </Grid>
                        </>
                    ))}
                    </Grid>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleOk}
                >
                    OK
                </Button>
            </CardContent>
        </Card>
    );
};

export default TicketScreen;
