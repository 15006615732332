import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SessionContextType {
    sessionId: string | null;
    setSessionId: (id: string) => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
    const [sessionId, setSessionIdState] = useState<string | null>(null);

    const setSessionId = (id: string) => {
        setSessionIdState(id);
    };

    return (
        <SessionContext.Provider value={{ sessionId, setSessionId }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = (): SessionContextType => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};
