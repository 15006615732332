import React, {useState, useEffect} from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogTitle, DialogContent, DialogContentText, Grid2 as Grid
} from '@mui/material';
import {Order, ActiveOrder, usePage} from "../../../context/PageContext";
import {useSession} from "../../../context/SessionContext";
import Button from "@mui/material/Button";
import LogoSVG from "../../../components/Mobile/MobileLogo/LogoSVG";
import OrderList from "../../../components/Mobile/MobileProducer/OrderList/OrderList";
import {useAppContext} from "../../../context/AppContext";

const CarrierSelectOrder: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const {sessionId} = useSession();
    const {setSelectedOrder, goToPage} = usePage();

    const {elevatorOptions, trailerLoadOptions} = useAppContext();

    const [sampleOrderList, setSampleOrderList] = useState<Order[]>([]);
    const [activeOrderList, setActiveOrderList] = useState<ActiveOrder[]>([]);
    const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

    const [producerFilter, setProducerFilter] = useState('');
    const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);

    // Fetch the sample and active order
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                // Get the sample orders
                let response = await fetch(`${process.env.REACT_APP_API_URL}/api/sample-orders/`);
                if (!response.ok) throw new Error('Failed to fetch sample orders');
                setSampleOrderList(await response.json());

                // Get the active orders by session ID
                response = await fetch(`${process.env.REACT_APP_API_URL}/api/active-orders/${sessionId}/`);
                if (!response.ok) throw new Error('Failed to fetch active orders');
                setActiveOrderList(await response.json());

                setLoading(false);
            } catch (err: any) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Helper function to check if the selected order is active
    const getActiveOrder = (order: Order) => {
        return activeOrderList.find(activeOrder => activeOrder.order_id === order.order_id);
    };

    const isOrderActive = (order: Order) => {
        return getActiveOrder(order) !== undefined;
    };


    const [viewOrder, setViewOrder] = useState<Order | null>(null);
    const [viewActiveOrder, setViewActiveOrder] = useState<ActiveOrder | null>(null);
    const [viewDialogueActiveOrder, setViewDialogueActiveOrder] = useState<boolean>(false);

    const handleViewActiveOrderInformation = (order: Order) => {
        const activeOrder = getActiveOrder(order);
        if (activeOrder) {
            setViewOrder(order);
            setViewActiveOrder(activeOrder);
            setViewDialogueActiveOrder(true);
        }
    };


    const handleSelectOrder = (order: Order) => {
        if (selectedOrderId === order.order_id) {
            setSelectedOrderId(null); // Deselect the order
            setSelectedOrder(null); // Clear the context
        } else {
            setSelectedOrderId(order.order_id); // Set the selected order locally
            setSelectedOrder(order); // Set the selected order in context
        }
    };

    // Extract unique producers and sort them by name
    const getUniqueProducers = () => {
        const producers = sampleOrderList.map(order => order.producer);
        const uniqueProducers = Array.from(new Set(producers));
        return uniqueProducers.sort();
    };

    // Update filtered orders based on producer selection
    useEffect(() => {
        if (producerFilter === '') {
            setFilteredOrders(sampleOrderList); // Show all orders if no filter is selected
        } else {
            const filtered = sampleOrderList.filter(order => order.producer === producerFilter);
            setFilteredOrders(filtered);
        }
    }, [producerFilter, sampleOrderList]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{position: 'relative', minHeight: '100vh', paddingBottom: '80px'}}>
                <Card sx={{ margin: 2 }}>
                    <CardContent>
                        <LogoSVG/>
                        <Typography fontSize="0.875rem" gutterBottom>
                            LVC Hauling
                        </Typography>
                        <Typography variant="subtitle1" sx={{fontSize: '0.875rem'}}>
                            Session ID: {sessionId}
                        </Typography>

                        <Typography variant="h6" sx={{mb: 2, fontWeight: 'bold', fontSize: '1rem'}}>
                            Open Orders ({sampleOrderList.length - activeOrderList.length})
                        </Typography>

                        {/* Producer Filter */}
                        <Box mt={2}>
                            <InputLabel id="producer-select-label">Producer</InputLabel>
                            <Select
                                labelId="producer-select-label"
                                id="producer-select"
                                value={producerFilter}
                                label="Producer"
                                onChange={e => setProducerFilter(e.target.value)}
                                variant="outlined"
                                fullWidth
                                displayEmpty
                            >
                                <MenuItem value="">All</MenuItem>
                                {getUniqueProducers().map(producer => (
                                    <MenuItem key={producer} value={producer}>
                                        {producer}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        {/* Display Sample Orders */}
                        <OrderList
                            sampleOrderList={filteredOrders}
                            activeOrderList={activeOrderList}
                            selectedOrderId={selectedOrderId}
                            handleSelectOrder={handleSelectOrder}
                            isOrderActive={isOrderActive}
                            viewActiveOrderDetail={handleViewActiveOrderInformation}
                        />

                    </CardContent>
                </Card>

                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        padding: '4px',
                        border: '1px solid',
                        borderColor: 'primary.main'
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => goToPage('Checkin1')}
                        disabled={!selectedOrderId} // Disable button if no order is selected
                    >
                        Check In
                    </Button>
                </Box>
            </Box>

            <Dialog
                open={viewDialogueActiveOrder}
                onClose={() => setViewDialogueActiveOrder(false)}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Ticket Information:</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={1}>
                            {/* Order */}
                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Order:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {viewOrder ? `${viewOrder.order_number} / ${viewOrder.item_number}` : "N/A"}
                                </Typography>
                            </Grid>

                            {/* Product */}
                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Product:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {viewOrder ? viewOrder.product : "N/A"}
                                </Typography>
                            </Grid>

                            {/* Previous loads */}
                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Previous loads 1:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {trailerLoadOptions.find(option => option.previous_loads_id === viewActiveOrder?.previous_load_1)?.commodity ?? 'N/A'}
                                </Typography>
                            </Grid>

                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Previous loads 2:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {trailerLoadOptions.find(option => option.previous_loads_id === viewActiveOrder?.previous_load_2)?.commodity ?? 'N/A'}
                                </Typography>
                            </Grid>

                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Previous loads 3:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {trailerLoadOptions.find(option => option.previous_loads_id === viewActiveOrder?.previous_load_3)?.commodity ?? 'N/A'}
                                </Typography>
                            </Grid>

                            {/* Delivery Elevator */}
                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Delivery Elevator:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    {elevatorOptions.find(option => option.location_id === viewActiveOrder?.location)?.location ?? 'N/A'}
                                </Typography>
                            </Grid>

                            {/* Probing */}
                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Dockage:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    0.5%
                                </Typography>
                            </Grid>

                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Moisture:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    10.2%
                                </Typography>
                            </Grid>

                            <Grid size={4}>
                                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                    Protein:
                                </Typography>
                            </Grid>
                            <Grid size={8}>
                                <Typography variant="body2">
                                    15.5%
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CarrierSelectOrder;
