import React, {ChangeEvent, useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    SelectChangeEvent,
    Grid2 as Grid, NativeSelect
} from '@mui/material';
import { useSession } from "../../context/SessionContext";
import {usePage} from '../../context/PageContext';
import {useAppContext} from "../../context/AppContext";
import {createOrUpdateActiveOrder} from "../../api/api";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";

const CheckInScreenOne: React.FC = () => {
    const { sessionId } = useSession();
    const { goToPage, selectedOrder, setActiveOrder } = usePage();

    const { elevatorOptions } = useAppContext();

    // Store fetch message for display
    const [message, setMessage] = useState<string | null>(null);

    const [deliveryElevator, setDeliveryElevator] = useState('');

    const handleElevatorChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setDeliveryElevator(event.target.value);  // Assuming this is a simple string state
    };

    const handleImHereClick = async () => {
        // Clear previous messages
        setMessage(null);

        // Session must exist
        if (!sessionId) {
            setMessage("No session id to create active order.");
            return;
        }

        // There must be a sample order selected
        if (!selectedOrder) {
            setMessage("No order selected. Please select an order before proceeding.");
            return;
        }

        // Check if deliveryElevator is selected
        if (!deliveryElevator) {
            setMessage("No delivery elevator selected. Please select a delivery elevator before proceeding.");
            return;
        }

        // Create a new active order
        createOrUpdateActiveOrder({
            session_id: sessionId,
            order_id: selectedOrder.order_id,
            previous_load_1: null,
            previous_load_2: null,
            previous_load_3: null,
            trailer_cleaning: null,
            location: elevatorOptions.find(option => option.location === deliveryElevator)?.location_id || -1,
            on_site: true,
            ready_for_probe: false,
            on_scale: false,
            probe_sampling: false,
            probe_sampling_completed: false,
            scale_gross_weight: false,
            scale_unloading_truck: false,
            scale_tare_weight: false,
            dumping_completed: false,
            truck_at_ticket_print_station: false,
            completed: false,
        }).then(data => {
            setActiveOrder(data.received_data);
            goToPage('Checkin2');
        });
    };

    // If selectedOrder is null, display an error message
    if (!selectedOrder) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" color="error" gutterBottom>
                        Error: No order selected.
                    </Typography>
                    <Typography>Please go back and select an order before proceeding to check-in.</Typography>
                </CardContent>
            </Card>
        );
    }

    if (message) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{message}</Typography>
            </Box>
        );
    }

    return (
        <Card sx={{ margin: 2 }}>
            <CardContent >
                <LogoSVG/>
                <Box mb={2}>
                    <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>
                        {selectedOrder.producer}
                    </Typography>

                    <Grid container spacing={1}>
                        {/* Order */}
                        <Grid  size={3}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Order:
                            </Typography>
                        </Grid>
                        <Grid  size={9}>
                            <Typography variant="body2">
                                {`${selectedOrder.order_number} / ${selectedOrder.item_number}`}
                            </Typography>
                        </Grid>

                        {/* Product */}
                        <Grid size={3}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Product:
                            </Typography>
                        </Grid>
                        <Grid size={9}>
                            <Typography variant="body2">
                                {selectedOrder.product}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="delivery-elevator-select" shrink={true} size={"normal"} variant={'standard'}>Delivery Elevator</InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'deliveryElevator',
                            id: 'delivery-elevator-select',
                        }}
                        value={deliveryElevator}
                        onChange={handleElevatorChange}
                    >
                        <option value="" disabled>
                            Please Choose a Location
                        </option>
                        {elevatorOptions.map((option) => (
                            <option key={option.location_id} value={option.location}>
                                {option.location}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleImHereClick}
                    sx={{ mt: 2 }}
                    disabled={deliveryElevator === ''}
                >
                    I'm Here
                </Button>
            </CardContent>
        </Card>
    );
};

export default CheckInScreenOne;
