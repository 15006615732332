import React, {useState} from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel, FormGroup,
    Grid2 as Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import {Search as SearchIcon} from '@mui/icons-material';
import Typography from "@mui/material/Typography";

interface HeaderProps {
    handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({handleLogout}) => {
    // State for "Show Tickets at Station(s)" toggle
    const [showTicketsAtStation, setShowTicketsAtStation] = useState<boolean>(false);

    // State for sorting
    const [sortOption, setSortOption] = useState<string>('ticketStartTimeOldest');

    // Get today's date in YYYY-MM-DD format for default value
    const today = new Date().toISOString().split('T')[0];

    return (
        <div className="header-filters border rounded-lg shadow-md p-4 grid grid-cols-1 xl:grid-cols-5 gap-4">
            {/* Filters Section */}
            <div className="col-span-1 xl:col-span-4 border p-4 rounded">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Filters</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">

                    {/* Ticket Date Filter */}
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <label className="text-sm text-gray-700">Ticket Date:</label>
                            <input
                                type="date"
                                defaultValue={today}
                                className="border rounded-md p-1 w-full focus:ring-2 focus:ring-blue-500 text-sm"
                            />
                        </div>
                        <FormControlLabel
                            control={<Checkbox className="rounded" defaultChecked/>}
                            label="Show older active tickets"
                            className="text-sm text-gray-700"
                        />
                    </div>

                    {/* Producer, Carrier, Product Class Filters */}
                    <div className="flex flex-col gap-2">
                        {/* Producer Dropdown with Search Icon */}
                        <div className="flex items-center w-full">
                            <Grid container spacing={2} sx={{width: "100%"}}>
                                <Grid size={4}>
                                    <Typography>Producer:</Typography>
                                </Grid>
                                <Grid size={8}>
                                    <div className="flex items-center w-full">
                                        <FormControl variant="outlined" size="small" className="flex-grow">
                                            <Select
                                                defaultValue=""
                                                displayEmpty
                                                input={<OutlinedInput/>}
                                            >
                                                <MenuItem value="">
                                                    All
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton size="small" className="ml-1 p-0">
                                            <SearchIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {/* Carrier Dropdown with Search Icon */}
                        <div className="flex items-center">
                            <Grid container spacing={2} sx={{width: "100%"}}>
                                <Grid size={4}>
                                    <Typography>Carrier:</Typography>
                                </Grid>
                                <Grid size={8}>
                                    <div className="flex items-center w-full">
                                        <FormControl variant="outlined" size="small" className="flex-grow">
                                            <Select
                                                defaultValue=""
                                                displayEmpty
                                                input={<OutlinedInput/>}
                                            >
                                                <MenuItem value="">
                                                    All
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <IconButton size="small" className="ml-1 p-0">
                                            <SearchIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>

                        {/* Product Class Dropdown */}
                        <Grid container spacing={2} sx={{width: "100%"}}>
                            <Grid size={4}>
                                <Typography variant={"body2"}>Product Class:</Typography>
                            </Grid>
                            <Grid size={8}>
                                <div className="flex items-center w-full">
                                    <FormControl variant="outlined" size="small" className="flex-grow">
                                        <Select
                                            defaultValue=""
                                            displayEmpty
                                            input={<OutlinedInput/>}

                                        >
                                            <MenuItem value="">
                                                All
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* Ticket Visibility Filters */}
                    <div className="flex flex-col gap-0 m-0">
                        <FormControlLabel
                            control={<Checkbox sx={{padding: "0"}} className="rounded" defaultChecked/>}
                            label="Show On-Site Tickets"
                            className="text-sm text-gray-700"
                        />
                        <div className="ml-4 flex flex-col gap-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{padding: "0"}}
                                        className="rounded"
                                        checked={showTicketsAtStation}
                                        onChange={(e) => setShowTicketsAtStation(e.target.checked)}
                                    />
                                }
                                label="Show Tickets at Station(s):"
                                className="text-sm text-gray-700"
                            />
                            <div className="flex items-center gap-1">
                                <input
                                    type="text"
                                    placeholder="Station"
                                    className="border rounded-md p-1 w-full focus:ring-2 focus:ring-blue-500 text-sm"
                                    disabled={!showTicketsAtStation}
                                />
                                <button
                                    className="px-2 pb-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600"
                                    disabled={!showTicketsAtStation}
                                >
                                    Change
                                </button>
                            </div>
                            <FormControlLabel
                                control={<Checkbox className="rounded" defaultChecked/>}
                                label="Include Queued Tickets"
                                className="text-sm text-gray-700"
                                disabled={!showTicketsAtStation}
                            />
                        </div>
                        <FormControlLabel
                            control={<Checkbox sx={{padding: "0"}} defaultChecked/>}
                            label="Show Queued Tickets"
                            className="text-sm text-gray-700 my-0"
                        />
                        <FormControlLabel
                            control={<Checkbox sx={{padding: "0"}} defaultChecked/>}
                            label="Show Finalized Tickets"
                            className="text-sm text-gray-700 my-0"
                        />
                        <FormControlLabel
                            control={<Checkbox sx={{padding: "0"}} defaultChecked/>}
                            label="Show Rejected Tickets"
                            className="text-sm text-gray-700 my-0"
                        />
                    </div>

                    {/* Sorting Options */}
                    <div className="flex flex-col gap-2">
                        <span className="text-sm font-medium text-gray-700">Sort Tickets by:</span>
                        <div className="flex flex-col">
                            {/* Sort by Ticket Start Time */}
                            <div className="flex flex-col ml-2">
                                <label className="flex items-center gap-2 text-sm text-gray-700">
                                    <input
                                        type="radio"
                                        name="sort"
                                        className="rounded"
                                        value="ticketStartTimeOldest"
                                        checked={sortOption === 'ticketStartTimeOldest'}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    />
                                    Sort Tickets by Ticket Start Time
                                </label>
                                <div className="ml-4 flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="sort"
                                        className="rounded"
                                        value="ticketStartTimeOldest"
                                        checked={sortOption === 'ticketStartTimeOldest'}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    />
                                    <label className="text-sm text-gray-700">Oldest to Newest</label>
                                </div>
                                <div className="ml-4 flex items-center gap-2">
                                    <input
                                        type="radio"
                                        name="sort"
                                        className="rounded"
                                        value="ticketStartTimeNewest"
                                        checked={sortOption === 'ticketStartTimeNewest'}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    />
                                    <label className="text-sm text-gray-700">Newest to Oldest</label>
                                </div>
                            </div>

                            {/* Sort by Order Number */}
                            <div className="flex items-center gap-2 ml-2">
                                <input
                                    type="radio"
                                    name="sort"
                                    className="rounded"
                                    value="orderNumber"
                                    checked={sortOption === 'orderNumber'}
                                    onChange={(e) => setSortOption(e.target.value)}
                                />
                                <label className="text-sm text-gray-700">Sort Tickets by Order Number</label>
                            </div>
                        </div>
                        <div className="flex gap-2 ml-4">
                            <button
                                className="px-2 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600">⬆️
                            </button>
                            <button
                                className="px-2 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600">⬇️
                            </button>
                        </div>
                    </div>
                </div>

                {/* Open Tickets Report Section */}

            </div>
            <div className="col-span-1 xl:col-span-1 border p-4 rounded">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Open Tickets Report</h2>
                <div className="flex flex-col gap-3">

                    {/* Filter By Date */}
                    <div className="flex items-center gap-2">
                        <input type="checkbox" className="rounded" defaultChecked/>
                        <label className="text-sm text-gray-700">Filter By Date:</label>
                        <input
                            type="date"
                            defaultValue={today}
                            className="border rounded-md p-1 w-full focus:ring-2 focus:ring-blue-500 text-sm"
                        />
                    </div>

                    {/* Include Active Tickets */}
                    <label className="flex items-center gap-2 text-sm text-gray-700">
                        <input type="checkbox" className="rounded" defaultChecked/> Include Active Tickets
                    </label>

                    {/* Include Queued Tickets */}
                    <label className="flex items-center gap-2 text-sm text-gray-700">
                        <input type="checkbox" className="rounded" defaultChecked/> Include Queued Tickets
                    </label>

                    {/* Preview Button */}
                    <div className="flex justify-end">
                        <button className="px-3 py-1 border rounded-md text-sm hover:bg-gray-100">
                            Preview
                        </button>
                    </div>

                    {/* Logout Button */}
                    <div className="flex justify-end mt-2">
                        <button
                            className="px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-600"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;