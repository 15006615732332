import React, {useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
    Button,
    Box, Grid2 as Grid, NativeSelect
} from '@mui/material';
import {usePage} from "../../context/PageContext";
import {useAppContext} from "../../context/AppContext";
import {createOrUpdateActiveOrder} from "../../api/api";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";

const CheckinTwo: React.FC = () => {
    const { selectedOrder, activeOrder, setActiveOrder, goToPage } = usePage();
    const { trailerLoadOptions, trailerCleaningOptions } = useAppContext();
    const { elevatorOptions } = useAppContext();

    const [load1, setLoad1] = useState('');
    const [load2, setLoad2] = useState('');
    const [load3, setLoad3] = useState('');
    const [trailerCleaning, setTrailerCleaning] = useState('');

    const isComplete = load1 && load2 && load3 && trailerCleaning;

    function getPreviousLoadId(commodity: string) {
        const load = trailerLoadOptions.find(load => load.commodity === commodity);
        return load ? load.previous_loads_id : null;
    }

    function getPreviousTrailerCleaningId(cleaning_method: string) {
        const cleaning = trailerCleaningOptions.find(cleaning => cleaning.cleaning_method === cleaning_method);
        return cleaning ? cleaning.trailer_cleaning_id : null;
    }

    const handleCompleteCheckin = async () => {
        if (!selectedOrder || !activeOrder) {
            console.error("Error: Missing session, selected order, or active order.");
            return;
        }

        // Finish checkin
        createOrUpdateActiveOrder({
            ...activeOrder,
            previous_load_1: getPreviousLoadId(load1),
            previous_load_2: getPreviousLoadId(load2),
            previous_load_3: getPreviousLoadId(load3),
            trailer_cleaning: getPreviousTrailerCleaningId(trailerCleaning),
            ready_for_probe: true
        }).then(data => {
            setActiveOrder(data.received_data);
            goToPage('Probing');
        });
    };

    // Render error messages for missing selectedOrder or activeOrder
    if (!selectedOrder) {
        return (
            <Card sx={{ margin: 2 }}>
                <CardContent>
                    <Typography variant="h5" color="error" gutterBottom>
                        Error: No order selected.
                    </Typography>
                    <Typography>Please go back and select an order before proceeding to check-in.</Typography>
                </CardContent>
            </Card>
        );
    }

    if (!activeOrder) {
        return (
            <Card sx={{ margin: 2 }}>
                <CardContent>
                    <Typography variant="h5" color="error" gutterBottom>
                        Error: No active order.
                    </Typography>
                    <Typography>No active order initialized.</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card sx={{ margin: 2 }}>
            <CardContent>
                <LogoSVG />
                <Box mb={5}>
                    <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>
                        {selectedOrder.producer}
                    </Typography>

                    <Grid container spacing={1}>
                        {/* Order */}
                        <Grid  size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Order:
                            </Typography>
                        </Grid>
                        <Grid  size={8}>
                            <Typography variant="body2">
                                {`${selectedOrder.order_number} / ${selectedOrder.item_number}`}
                            </Typography>
                        </Grid>

                        {/* Product */}
                        <Grid size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Product:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                {selectedOrder.product}
                            </Typography>
                        </Grid>

                        {/* Delivery Elevator */}
                        <Grid size={4}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Delivery Elevator:
                            </Typography>
                        </Grid>
                        <Grid size={8}>
                            <Typography variant="body2">
                                {elevatorOptions.find(option => option.location_id === activeOrder?.location)?.location}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel
                        htmlFor="load1-select"
                        shrink={true}
                        size="normal"
                        variant="standard"
                    >
                        Previous Load 1
                    </InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'load1',
                            id: 'load1-select',
                        }}
                        value={load1}
                        onChange={(e) => setLoad1(e.target.value)}
                    >
                        <option value="" disabled>
                            Empty
                        </option>
                        {trailerLoadOptions.map((option, index) => (
                            <option key={index} value={option.commodity}>
                                {option.commodity}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel
                        htmlFor="load2-select"
                        shrink={true}
                        size="normal"
                        variant="standard"
                    >
                        Previous Load 2
                    </InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'load2',
                            id: 'load2-select',
                        }}
                        value={load2}
                        onChange={(e) => setLoad2(e.target.value)}
                    >
                        <option value="" disabled>
                            Empty
                        </option>
                        {trailerLoadOptions.map((option, index) => (
                            <option key={index} value={option.commodity}>
                                {option.commodity}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel
                        htmlFor="load3-select"
                        shrink={true}
                        size="normal"
                        variant="standard"
                    >
                        Previous Load 3
                    </InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'load3',
                            id: 'load3-select',
                        }}
                        value={load3}
                        onChange={(e) => setLoad3(e.target.value)}
                    >
                        <option value="" disabled>
                            Empty
                        </option>
                        {trailerLoadOptions.map((option, index) => (
                            <option key={index} value={option.commodity}>
                                {option.commodity}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel
                        htmlFor="trailer-cleaning-select"
                        shrink={true}
                        size="normal"
                        variant="standard"
                    >
                        Trailer Cleaning Method
                    </InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'trailerCleaning',
                            id: 'trailer-cleaning-select',
                        }}
                        value={trailerCleaning}
                        onChange={(e) => setTrailerCleaning(e.target.value)}
                    >
                        <option value="" disabled>
                            None
                        </option>
                        {trailerCleaningOptions.map((option, index) => (
                            <option key={index} value={option.cleaning_method}>
                                {option.cleaning_method}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleCompleteCheckin} // Handle the click event
                    sx={{ mt: 2 }}
                    disabled={!isComplete} // Disable if form is incomplete
                >
                    Complete Check In
                </Button>
            </CardContent>
        </Card>
    );
};

export default CheckinTwo;