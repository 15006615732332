import React, { createContext, useContext, useState } from 'react';

// Pages available to the single page application
type Page = 'SelectOrder' | 'Checkin1' | 'Checkin2' | 'Probing' | 'Dumping' | 'Ticket';

export interface Order {
    order_id: number;
    order_number: number;
    item_number: number;
    contract: string;
    producer: string;
    carrier: string;
    product: string;
    amount: string;
}

export interface ActiveOrder {
    session_id: string;
    order_id: number;
    previous_load_1: number | null;
    previous_load_2: number | null;
    previous_load_3: number | null;
    trailer_cleaning: number | null;
    location: number | null;
    on_site: boolean;
    ready_for_probe: boolean;
    on_scale: boolean;

    probe_sampling: boolean;
    probe_sampling_completed: boolean;
    scale_gross_weight: boolean;
    scale_unloading_truck: boolean;
    scale_tare_weight: boolean;
    dumping_completed: boolean;

    truck_at_ticket_print_station: boolean;

    completed: boolean;
}

// Context
interface PageContextType {
    currentPage: Page;
    selectedOrder: Order | null; // Store the selected order
    activeOrder: ActiveOrder | null; // Store the active order
    goToPage: (page: Page) => void;
    setSelectedOrder: (order: Order | null) => void;
    setActiveOrder: (order: ActiveOrder | null) => void; // Add a setter for the active order
}

const PageContext = createContext<PageContextType | undefined>(undefined);

export const PageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState<Page>('SelectOrder');
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [activeOrder, setActiveOrder] = useState<ActiveOrder | null>(null); // Initialize state for active order

    const goToPage = (page: Page) => {
        setCurrentPage(page);
    };

    return (
        <PageContext.Provider value={{
            currentPage, selectedOrder, activeOrder, goToPage, setSelectedOrder, setActiveOrder
        }}>
            {children}
        </PageContext.Provider>
    );
};

export const usePage = (): PageContextType => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error('usePage must be used within a ProducerPageProvider');
    }
    return context;
};
