import React, { useState } from 'react';
import LvTruckLogin from '../../pages/LvTruckLogin';
import LvTruckPage from "../../pages/LvTruck/LvTruckPage";

function LvTruck() {
    const [currentPage, setCurrentPage] = useState<'Login' | 'LV-TRUCK'>('Login');

    const handlePageChange = (changeTo: 'Login' | 'LV-TRUCK') => {
        setCurrentPage(changeTo);
    };

    return (
        <div>
            {currentPage === 'Login' && <LvTruckLogin onLoginSuccess={handlePageChange} />}
            {currentPage === 'LV-TRUCK' && <LvTruckPage handlePageChange={handlePageChange} />}
        </div>
    );
}

export default LvTruck;
