import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid2 as Grid,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  IconButton,
} from "@mui/material";
import { usePage } from "../../context/PageContext";
import { useAppContext } from "../../context/AppContext";
import { createOrUpdateActiveOrder } from "../../api/api";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";
import { TransitionProps } from "@mui/material/transitions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DumpingScreen: React.FC = () => {
  const { selectedOrder, activeOrder, setActiveOrder, goToPage } = usePage();
  const { elevatorOptions } = useAppContext();

  const [drivewayStatus, setDrivewayStatus] = useState("Waiting...");
  const [grossWeight, setGrossWeight] = useState<number | null>(null);
  const [tareWeight, setTareWeight] = useState<number | null>(null);
  const [netWeight, setNetWeight] = useState<number | null>(null);

  const [buttonLabel, setButtonLabel] = useState("Truck is on Scale");
  const [viewDialogue, setViewDialogue] = useState(false);
  const handleTruckOnScale = async () => {
    if (!selectedOrder || !activeOrder) {
      console.error("Error: Missing session, selected order, or active order.");
      return;
    }

    if (drivewayStatus === "Waiting...") {
      // On scale and taking gross weight
      createOrUpdateActiveOrder({
        ...activeOrder,
        on_scale: true,
        scale_gross_weight: true,
      }).then((data) => {
        setActiveOrder(data.received_data);
      });

      // Simulate dumping process
      setDrivewayStatus("Taking Gross Weight...");
      setTimeout(() => {
        setGrossWeight(65000); // Simulate the gross weighting

        // Unloading truck
        createOrUpdateActiveOrder({
          ...activeOrder,
          on_scale: true,
          scale_gross_weight: true,
          scale_unloading_truck: true,
        }).then((data) => {
          setActiveOrder(data.received_data);
        });

        setDrivewayStatus("Unloading Truck...");

        setTimeout(() => {
          // Scale tare weight
          createOrUpdateActiveOrder({
            ...activeOrder,
            on_scale: true,
            scale_gross_weight: true,
            scale_unloading_truck: true,
            scale_tare_weight: true,
          }).then((data) => {
            setActiveOrder(data.received_data);
          });

          setDrivewayStatus("Taking Tare Weight...");
          setTimeout(async () => {
            setTareWeight(20000);

            // Completed
            createOrUpdateActiveOrder({
              ...activeOrder,
              on_scale: true,
              scale_gross_weight: true,
              scale_unloading_truck: true,
              scale_tare_weight: true,
              dumping_completed: true,
            }).then((data) => {
              setActiveOrder(data.received_data);
            });

            setNetWeight(45000);
            setDrivewayStatus("Unloading Completed!");
            setViewDialogue(true);
            setButtonLabel("Proceed to Ticket Printer");
          }, 2000); // Delay for tare weight
        }, 10000); // Delay for unloading
      }, 2000); // Delay for gross weight
    } else {

      // Go to ticket station
      createOrUpdateActiveOrder({
        ...activeOrder,
        truck_at_ticket_print_station: true
      }).then((data) => {
        setActiveOrder(data.received_data);
      });

      goToPage("Ticket");
    }
  };

  // Render error messages for missing selectedOrder or activeOrder
  if (!selectedOrder) {
    return (
      <Card sx={{ margin: 2 }}>
        <CardContent>
          <Typography variant="h5" color="error" gutterBottom>
            Error: No order selected.
          </Typography>
          <Typography>
            Please go back and select an order before proceeding to check-in.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ margin: 2 }}>
      <CardContent>
        <LogoSVG />
        <Box mb={2}>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {selectedOrder.producer}
          </Typography>

          <Grid container spacing={1}>
            {/* Order */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Order:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                {`${selectedOrder.order_number} / ${selectedOrder.item_number}`}
              </Typography>
            </Grid>

            {/* Product */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Product:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{selectedOrder.product}</Typography>
            </Grid>

            {/* Delivery Elevator */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Delivery Elevator:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                {
                  elevatorOptions.find(
                    (option) => option.location_id === activeOrder?.location
                  )?.location
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginY: 5 }}>
            {/* Delivery Elevator */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Driveway Status:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  color: "#28a745", // success green color
                  animation: "fadeInOut 2s ease-in-out infinite",
                  "@keyframes fadeInOut": {
                    "0%": { opacity: 0.5 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.5 },
                  },
                }}
              >
                {drivewayStatus}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <Grid container spacing={1}>
            {/* Gross Weight */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Gross Weight:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{grossWeight ? grossWeight.toLocaleString() + " kg" : "N/A"}</Typography>
            </Grid>

            {/* Tare Weight */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Tare Weight:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{tareWeight ? tareWeight.toLocaleString() + " kg" : "N/A"}</Typography>
            </Grid>

            {/* Net Weight */}
            <Grid size={4}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Net Weight:
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{netWeight ? netWeight.toLocaleString() + " kg" : "N/A"}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
          onClick={handleTruckOnScale}
          disabled={
            drivewayStatus !== "Waiting..." &&
            drivewayStatus !== "Unloading Completed!"
          }
        >
          {buttonLabel}
        </Button>
      </CardContent>
      <Dialog
        open={viewDialogue}
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <div className="w-full flex text-sm items-center justify-end">
          <IconButton
            aria-label="close"
            onClick={() => {
              setViewDialogue(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <CheckCircleOutlineIcon color={"success"}></CheckCircleOutlineIcon>{" "}
            Unloading Completed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            fullWidth
            onClick={handleTruckOnScale}
            disabled={
              drivewayStatus !== "Waiting..." &&
              drivewayStatus !== "Unloading Completed!"
            }
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DumpingScreen;
