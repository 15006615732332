import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MobileApp from "./routes/mobile-app/MobileApp";
import LvGtiSimulator from "./routes/lv-gti-simulator/LvGtiSimulator";
import LandingPage from "./routes/LandingPage";
import {SessionProvider} from "./context/SessionContext";
import {PageProvider} from "./context/PageContext";
import {AppContextProvider} from "./context/AppContext";
import LvTruck from "./routes/lv-truck/LvTruck";

function App() {
    return (
        <SessionProvider>
            <PageProvider>
                <AppContextProvider>
                    <Router>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/mobile-app" element={<MobileApp />} />
                            <Route path="/lv-gti-simulator" element={<LvGtiSimulator />} />
                            <Route path="/lv-truck" element={<LvTruck />} />
                        </Routes>
                    </Router>
                </AppContextProvider>
            </PageProvider>
        </SessionProvider>
    );
}

export default App;
