import React, { useState } from 'react';
import MobileAppLogin from "../../pages/MobileAppLogin";
import ProducerPage from "../../pages/Producer/ProducerPage";
import CarrierPage from "../../pages/Carrier/CarrierPage";

function MobileApp() {
    const [currentPage, setCurrentPage] = useState<'Login' | 'Producer' | 'Carrier'>('Login');

    const handleAppPageChange = (accountType: string) => {
        if (accountType === 'Producer' || accountType === 'Carrier')
        {
            setCurrentPage(accountType);
        }
    };

    return (
        <div className="w-full max-w-lg mx-auto p-4 sm:p-6 md:max-w-2xl lg:max-w-4xl">
            {currentPage === 'Login' && <MobileAppLogin onLoginSuccess={handleAppPageChange}/>}
            {currentPage === 'Producer' && <ProducerPage/>}
            {currentPage === 'Carrier' && <CarrierPage/>}
        </div>
    );
}

export default MobileApp;
