import React, { useState } from 'react';
import LvGtiLogin from "../../pages/LvGtiLogin";
import LvGtiSimulationPage from "../../pages/LvGtiSimulation/LvGtiSimulationPage";

function LvGtiSimulator() {
    const [currentPage, setCurrentPage] = useState<'Login' | 'LV-GTI-SIMULATION'>('Login');

    const handlePageChange = (changeTo: 'Login' | 'LV-GTI-SIMULATION') => {
        setCurrentPage(changeTo);
    };

    return (
        <div>
            {currentPage === 'Login' && <LvGtiLogin onLoginSuccess={handlePageChange} />}
            {currentPage === 'LV-GTI-SIMULATION' && <LvGtiSimulationPage handlePageChange={handlePageChange} />}
        </div>
    );
}

export default LvGtiSimulator;
