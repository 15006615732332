import React from 'react';
import {usePage} from "../../context/PageContext";
import CheckinOne from "../pages/CheckinOne";
import CheckinTwo from "../pages/CheckinTwo";
import Probing from "../pages/Probing";
import Dumping from "../pages/Dumping";
import Ticket from "../pages/Ticket";
import CarrierSelectOrder from "./pages/CarrierSelectOrder";

const CarrierPage: React.FC = () => {
    const { currentPage } = usePage();

    return (
        <div>
            {currentPage === 'SelectOrder' && <CarrierSelectOrder/>}
            {currentPage === 'Checkin1' && <CheckinOne/>}
            {currentPage === 'Checkin2' && <CheckinTwo />}
            {currentPage === 'Probing' && <Probing />}
            {currentPage === 'Dumping' && <Dumping />}
            {currentPage === 'Ticket' && <Ticket />}
        </div>
    );
};

export default CarrierPage;
