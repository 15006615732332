import React, { useState } from 'react';
import { useSession } from '../context/SessionContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Alert,
    Box,
    CircularProgress,
    Container,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar
} from '@mui/material';

interface LoginProps {
    onLoginSuccess: (changeTo: 'Login' | 'LV-TRUCK') => void;
}

const MobileAppLogin: React.FC<LoginProps> = ({ onLoginSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const { setSessionId } = useSession();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>(''); // You may add password handling if required
    const [showEmailError, setShowEmailError] = useState<boolean>(false);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setShowEmailError(true);
            return;
        }

        setLoading(true);
        setError(null); // Clear any previous errors

        try {
            // Make the POST request to your API endpoint
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }), // Send the email in the request body
            });

            if (!response.ok) {
                throw new Error('Login failed, please check your credentials.');
            }

            const data = await response.json();

            // Assuming the session ID is part of the response (adjust accordingly)
            const sessionId = data.session_id; // or adjust to the actual field
            if (!sessionId) {
                throw new Error('Session ID not found in the response.');
            }

            // Set the session ID in the global context
            setSessionId(sessionId);

            // Trigger the callback to switch the page
            onLoginSuccess('LV-TRUCK');

        } catch (err: any) {
            setError(err.message); // Display the error message
        }

        setLoading(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Snackbar
                open={showEmailError} autoHideDuration={12000}
                onClose={() => setShowEmailError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setShowEmailError(false)} severity="error" sx={{ width: '100%' }}>
                    Please enter a valid email address.
                </Alert>
            </Snackbar>

            <Card>
                <CardContent>
                    <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
                        LV CONTROL
                    </Typography>
                    <Typography component="h2" variant="subtitle1" sx={{ textAlign: 'center', mb: 4, color: 'text.secondary' }}>
                         LV Truck
                    </Typography>
                    <form onSubmit={handleLogin} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"

                            focused
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"

                            focused
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
};

export default MobileAppLogin;
