import React from 'react';
import {
    Typography,
    Box,
    Paper,
    Grid2 as Grid,
    Button,
} from '@mui/material';
import {ActiveOrder, Order} from '../../../../context/PageContext';

// Define the props interface based on your context types
interface OrderListProps {
    sampleOrderList: Order[];
    activeOrderList: ActiveOrder[];
    selectedOrderId: number | null;
    handleSelectOrder: (order: Order) => void;
    isOrderActive: (order: Order) => boolean;
    viewActiveOrderDetail: (order: Order) => void;
}

const OrderList: React.FC<OrderListProps> = ({
                                                 sampleOrderList,
                                                 activeOrderList,
                                                 selectedOrderId,
                                                 handleSelectOrder,
                                                 isOrderActive,
                                                 viewActiveOrderDetail
                                             }) => {
    return (
        <Box sx={{mt: 2}}>
            <Typography variant="h6" sx={{mb: 2, fontWeight: 'bold', fontSize: '1rem'}}>
                Open Orders ({sampleOrderList.length - activeOrderList.length})
            </Typography>
            <Box>
                {sampleOrderList.map((order) => (
                        <Paper
                            key={order.order_id}
                            sx={{
                                p: 2,
                                mb: 2,
                                borderRadius: 2,
                                boxShadow: 1,
                            }}
                        >
                            <Grid container spacing={1}>
                                {/* Order Number (Bold) */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Order Number:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        {order.order_number}
                                    </Typography>
                                </Grid>

                                {/* Item Number */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Item Number:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.item_number}
                                    </Typography>
                                </Grid>

                                {/* Contract */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Contract:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.contract}
                                    </Typography>
                                </Grid>

                                {/* Producer */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Producer:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.producer}
                                    </Typography>
                                </Grid>

                                {/* Carrier */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Carrier:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.carrier}
                                    </Typography>
                                </Grid>

                                {/* Product */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Product:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.product}
                                    </Typography>
                                </Grid>

                                {/* Amount */}
                                <Grid size={5}>
                                    <Typography variant="body2" sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>
                                        Amount:
                                    </Typography>
                                </Grid>
                                <Grid size={7}>
                                    <Typography variant="body2" sx={{fontSize: '0.875rem'}}>
                                        {order.amount}
                                    </Typography>
                                </Grid>

                                {/* Select Button */}
                                <Grid size={12} sx={{textAlign: 'left', mt: 1}}>
                                    <div className="flex flex-row gap-2">
                                        <Button
                                            variant={selectedOrderId === order.order_id ? 'contained' : 'outlined'}
                                            color="primary"
                                            onClick={() => handleSelectOrder(order)}
                                            disabled={isOrderActive(order)}
                                            sx={{fontSize: '0.875rem', padding: '6px 12px'}}
                                        >
                                            {selectedOrderId === order.order_id ? 'Selected' : 'Select'}
                                        </Button>

                                        <Button
                                            variant={selectedOrderId === order.order_id ? 'contained' : 'outlined'}
                                            color="secondary"
                                            onClick={() => viewActiveOrderDetail(order)}
                                            sx={{
                                                fontSize: '0.875rem',
                                                padding: '6px 12px',
                                                display: !isOrderActive(order) ? "none" : null
                                            }}
                                        >
                                            {"View Order"}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
            </Box>
        </Box>
    );
};

export default OrderList;
