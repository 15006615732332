import React, {useEffect, useState} from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import TruckOnScale from "../../components/LvTruck/TruckOnScale";
import Scale from "../../components/LvTruck/Scale";
import {useSession} from "../../context/SessionContext";
import {ActiveOrder, Order} from "../../context/PageContext";
import OrderItem from "../LvGtiSimulation/components/OrderList/OrderItem";
import LogoSVG from "../../components/Mobile/MobileLogo/LogoSVG";
import logoSrc from "../../assets/Logo/logo.svg";

interface LvTruckProps {
    handlePageChange: (changeTo: 'Login' | 'LV-TRUCK') => void;
}

const LvTruck: React.FC<LvTruckProps> = ({handlePageChange}) => {

    const handleLogout = () => {
        handlePageChange('Login');  // This triggers the page change to Login
    };


    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const {sessionId} = useSession();

    const [sampleOrderList, setSampleOrderList] = useState<Order[]>([]);
    const [activeOrderList, setActiveOrderList] = useState<ActiveOrder[]>([]);

    // Fetch the sample and active orders
    useEffect(() => {
        let continueToFetch = true;
        let intervalDuration = 1000; // Initial interval duration (1 second)
        let intervalId: NodeJS.Timeout | null = null;
        let timeoutId: NodeJS.Timeout | null = null;

        const fetchOrders = async () => {
            if (continueToFetch)
            {
                console.log("I am fetching...");

                try {
                    // Get the sample orders
                    let response = await fetch(`${process.env.REACT_APP_API_URL}/api/sample-orders/`);
                    if (!response.ok) throw new Error('Failed to fetch sample orders');
                    setSampleOrderList(await response.json());

                    // Get the active orders by session ID
                    response = await fetch(`${process.env.REACT_APP_API_URL}/api/active-orders/${sessionId}/`);
                    if (!response.ok) throw new Error('Failed to fetch active orders');
                    setActiveOrderList(await response.json());

                    setLoading(false);
                } catch (err: any) {
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        const startFetching = () => {
            if (intervalId) clearInterval(intervalId); // Clear the previous interval

            intervalId = setInterval(fetchOrders, intervalDuration);
        };

        const adjustInterval = () => {
            // Increase interval duration by 1 second every minute
            setTimeout(() => {
                intervalDuration += 1000; // Increase interval by 1 second
                startFetching(); // Restart interval with the new duration
                adjustInterval(); // Schedule the next adjustment
            }, 60000); // Run every 1 minute
        };

        const stopFetching = () => {
            continueToFetch = false;
            if (intervalId) clearInterval(intervalId); // Stop the fetching interval
            if (timeoutId) clearTimeout(timeoutId); // Stop any pending timeouts
        };

        // Start the fetching process
        startFetching();
        adjustInterval();

        // Stop fetching after 5 minutes
        timeoutId = setTimeout(() => {
            console.log("Stop fetching");
            stopFetching();
        }, 300000); // 5 minutes = 300,000 ms

        // Cleanup intervals and timeouts on unmount
        return () => {
            stopFetching();
        };
    }, [sessionId]);


    const getSampleOrderByOrderId = (orderId: number): Order | null => {
        // Find the sample order that matches the given order_id
        const sampleOrder = sampleOrderList.find(order => order.order_id === orderId);
        return sampleOrder || null; // Return null if not found
    };


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const onSite = () => {
        const filteredActiveOrders = activeOrderList.filter(order =>
            order.on_site &&
            !order.ready_for_probe &&
            !order.on_scale &&
            !order.probe_sampling &&
            !order.probe_sampling_completed &&
            !order.scale_gross_weight &&
            !order.scale_unloading_truck &&
            !order.scale_tare_weight &&
            !order.dumping_completed &&
            !order.completed
        );

        // No truck is on site and trying to check in
        if (filteredActiveOrders.length == 0) {
            return (
                <>
                    {/* Vertical Text */}
                    <span className="rotate-[-90deg] text-white">ENTER</span>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Check In Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <Scale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Not Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    N/A
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>
                </>
            );
        }
        // Otherwise display the first truck that is trying to check in
        else {
            // Display the first active order
            const activeOrder = filteredActiveOrders[0];
            const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);

            return (
                <>
                    {/* Vertical Text */}
                    <span className="rotate-[-90deg] text-white">ENTER</span>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Check In Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <TruckOnScale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    {sampleOrder?.order_number || 'N/A'}
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>
                </>
            )
        }
    }

    const readyForProbeQueue = () => {
        const filteredActiveOrders = activeOrderList.filter(order =>
            order.on_site &&
            (order.ready_for_probe ||
                order.probe_sampling ||
                order.probe_sampling_completed) &&
            !order.on_scale &&
            !order.scale_gross_weight &&
            !order.scale_unloading_truck &&
            !order.scale_tare_weight &&
            !order.dumping_completed &&
            !order.completed
        );

        return (
            <>
                {/* Truck queue */}
                <div
                    className="flex flex-col text-white border-2 border-white p-4 gap-2"
                >
                    {/* Header */}
                    <div>
                        Truck Queue
                    </div>

                    {/* Queue list */}
                    <div className="w-full h-48 bg-white text-black flex flex-col overflow-y-auto">
                        {
                            filteredActiveOrders.map(activeOrder => {
                                const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);
                                return (
                                    <div key={activeOrder.order_id}
                                         className="p-2 whitespace-nowrap overflow-hidden text-ellipsis select-none">
                                        {sampleOrder?.order_number || 'N/A'}
                                    </div>
                                );
                            })
                        }
                    </div>

                    {/* Buttons */}
                    <div className="flex flex-col items-center">
                        <button
                            className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                            disabled
                        >
                            Truck Details
                        </button>
                    </div>
                </div>
            </>
        );
    }

    const weighting = () => {
        const filteredActiveOrders = activeOrderList.filter(order =>
            order.on_site &&
            order.ready_for_probe &&
            order.probe_sampling &&
            order.probe_sampling_completed &&
            (
                order.on_scale ||
                order.scale_gross_weight ||
                order.scale_unloading_truck ||
                order.scale_tare_weight
            ) &&
            !order.dumping_completed &&
            !order.completed
        );

        // No truck is currently probing or weighting
        if (filteredActiveOrders.length == 0) {
            return (
                <>
                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Driveway 1 Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Weight scale */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <div className="bg-black border-2 border-white flex justify-end px-2">
                                <span className="text-white">0 kg</span>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <Scale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Not Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    N/A
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>
                </>
            );
        }
        // Otherwise display the first truck in the process of probing or weighting
        else {
            // Display the first active order
            const activeOrder = filteredActiveOrders[0];
            const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);


            let weight = 0;
            if (activeOrder.scale_unloading_truck) {
                weight = 65000;
            }

            return (
                <>
                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Driveway 1 Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Weight scale */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <div className="bg-black border-2 border-white flex justify-end px-2">
                                <span className="text-white">{weight.toLocaleString()} kg</span>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <TruckOnScale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    {sampleOrder?.order_number || 'N/A'}
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>
                </>
            );
        }
    }


    const dumpingCompletedQueue = () => {
        const filteredActiveOrders = activeOrderList.filter(order =>
            order.on_site &&
            order.ready_for_probe &&
            order.probe_sampling &&
            order.probe_sampling_completed &&
            order.on_scale &&
            order.scale_gross_weight &&
            order.scale_unloading_truck &&
            order.scale_tare_weight &&
            order.dumping_completed &&
            !order.truck_at_ticket_print_station &&
            !order.completed
        );

        return (
            <>
                {/* Truck queue */}
                <div
                    className="flex flex-col text-white border-2 border-white p-4 gap-2"
                >
                    {/* Header */}
                    <div>
                        Truck Queue
                    </div>

                    {/* Queue list */}
                    <div className="w-full h-48 bg-white text-black flex flex-col overflow-y-auto">
                        {
                            filteredActiveOrders.map(activeOrder => {
                                const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);
                                return (
                                    <div key={activeOrder.order_id}
                                         className="p-2 whitespace-nowrap overflow-hidden text-ellipsis select-none">
                                        {sampleOrder?.order_number || 'N/A'}
                                    </div>
                                );
                            })
                        }
                    </div>

                    {/* Buttons */}
                    <div className="flex flex-col items-center">
                        <button
                            className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                            disabled
                        >
                            Truck Details
                        </button>
                    </div>
                </div>
            </>
        );
    }


    const printReceipt = () => {
        const filteredActiveOrders = activeOrderList.filter(order =>
            order.on_site &&
            order.ready_for_probe &&
            order.probe_sampling &&
            order.probe_sampling_completed &&
            order.on_scale &&
            order.scale_gross_weight &&
            order.scale_unloading_truck &&
            order.scale_tare_weight &&
            order.dumping_completed &&
            order.truck_at_ticket_print_station &&
            !order.completed
        );

        // No truck is getting print receipt
        if (filteredActiveOrders.length == 0) {
            return (
                <>
                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Printer Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <Scale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Not Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    N/A
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Vertical Text */}
                    <span className="rotate-[-90deg] text-white">EXIT</span>
                </>
            );
        } else {
            // Display the first active order
            const activeOrder = filteredActiveOrders[0];
            const sampleOrder = getSampleOrderByOrderId(activeOrder.order_id);

            return (
                <>
                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Truck information */}
                    <div
                        className="flex flex-col text-white border-2 border-white p-4 gap-2"
                    >
                        {/* Grid Layout for Header */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Printer Station
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center">
                                <div className="w-full text-green-500 bg-black border-2 border-white px-2">
                                    OK
                                </div>
                            </div>
                        </div>

                        {/* Truck station image */}
                        <div className="flex justify-center items-center">
                            <TruckOnScale/>
                        </div>

                        {/* "Truck Present" or "Truck Not Present" */}
                        <div className="bg-black border-2 border-white flex justify-center items-center">
                            <span className="text-white">Truck Present</span>
                        </div>

                        {/* Order number */}
                        <div className="grid grid-cols-3 w-full gap-2">
                            {/* Column 1: Check In Station */}
                            <div>
                                Order #:
                            </div>
                            {/* Column 2: OK */}
                            <div className="flex flex-row items-center col-span-2">
                                <div
                                    className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                    {sampleOrder?.order_number || 'N/A'}
                                </div>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="grid grid-cols-2 w-full gap-2">
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Station Details
                            </button>
                            <button
                                className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Truck Details
                            </button>
                        </div>
                    </div>

                    {/* Arrow Icon */}
                    <div className="flex-grow flex items-center justify-center">
                        {/* Arrow Icon with width that adjusts to container */}
                        <KeyboardArrowRightIcon
                            className="text-[#0200F7]"
                        />
                    </div>

                    {/* Vertical Text */}
                    <span className="rotate-[-90deg] text-white">EXIT</span>
                </>
            );
        }
    }


    return (
        <div
            className="min-h-screen w-full flex flex-col"
            style={{backgroundColor: '#008081'}}
        >
            {/* Header */}
            <div className="flex flex-row justify-end items-start gap-2 p-2">
                {/* Reset Communication Alarms Button */}
                <button
                    className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                    disabled
                >
                    Reset Communication Alarms
                </button>

                <div
                    className="flex flex-col text-white border-2 border-white p-1 gap-2"
                >
                    <div className="flex flex-row items-center gap-2">
                        {/* Logo */}
                        <div>
                            <img
                                src={logoSrc}
                                alt="LV CONTROL logo"
                                style={{
                                    width: '100%',
                                    height: 'auto', // Maintain aspect ratio
                                }}
                            />
                        </div>

                        {/* Logout Button */}
                        <div>
                            <button
                                className="px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-600"
                                onClick={handleLogout}
                            >
                                Logout
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 w-full gap-2">
                        <div>
                            Current User:
                        </div>

                        <div className="flex flex-row items-center col-span-2">
                            <div
                                className="w-full text-white bg-black border-2 border-white px-2 overflow-hidden whitespace-nowrap text-ellipsis">
                                LV Control Administration
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex flex-row flex-grow">
                <div className="flex-grow w-[80%]">
                    <div
                        className="h-full flex-grow grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-[1fr_0.5fr_1fr_0.5fr_1fr]"
                    >
                        <div
                            className="flex flex-row justify-center items-center"
                        >
                            {onSite()}
                        </div>

                        <div className="flex flex-row justify-center items-center">
                            {readyForProbeQueue()}
                        </div>

                        <div className="flex flex-row justify-center items-center">
                            {weighting()}
                        </div>

                        <div className="flex flex-row justify-center items-center">
                            {dumpingCompletedQueue()}
                        </div>

                        <div
                            className="flex flex-row justify-center items-center"
                        >
                            {printReceipt()}
                        </div>
                    </div>
                </div>

                {/* Panel Section */}
                <div
                    className="w-[20%] bg-white border-l-2 border-gray-300 p-2 flex flex-col gap-2"
                >
                    <div className="flex flex-row justify-between items-end">
                        {/* Title */}
                        <div className="text-[10px] font-bold">
                            Order List
                        </div>

                        {/* Refresh Button */}
                        <div>
                            <button
                                className="bg-white text-black text-[9px] p-0.5 rounded border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Refresh
                            </button>
                        </div>
                    </div>

                    {/* Scrollable Table */}
                    <div className="h-full border border-gray-300 bg-white text-xs">
                        {/* Table Header */}
                        <div className="grid grid-cols-3 bg-gray-200 border-b border-gray-300 p-1">
                            <div className="font-bold text-[10px]">Order Number</div>
                            <div className="font-bold text-[10px]">Carrier</div>
                            <div className="font-bold text-[10px]">Producer</div>
                        </div>

                        {/* Empty Table Body */}
                        <div></div>
                    </div>

                    <button
                        className="bg-white text-black text-xs p-0.5 rounded-sm border border-black hover:bg-gray-100 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                        disabled
                    >
                        Station Details
                    </button>

                    {/* Filter Section */}
                    <div className="border border-gray-300 bg-white p-1 flex flex-col gap-1">
                        {/* Title */}
                        <h3 className="text-[10px] font-bold">Filter</h3>

                        {/* Filter Options */}
                        <div className="flex flex-col gap-0.5">
                            {/* Show entries for next */}
                            <div className="flex items-center gap-0.5">
                                <label htmlFor="time-range" className="text-[9px] font-medium">
                                    Show entries for next
                                </label>
                                <select
                                    id="time-range"
                                    className="border border-gray-300 rounded p-0.5 text-[9px]"
                                    disabled
                                >
                                    <option>24 hours</option>
                                    <option>48 hours</option>
                                    <option>1 week</option>
                                    <option>1 month</option>
                                </select>
                            </div>

                            {/* Include Late Orders */}
                            <div className="flex items-center gap-0.5">
                                <input
                                    type="checkbox"
                                    id="late-orders"
                                    className="w-3 h-3"
                                    disabled
                                />
                                <label htmlFor="late-orders" className="text-[9px] font-medium">
                                    Include Late Orders
                                </label>
                            </div>

                            {/* Show Only Orders on Site */}
                            <div className="flex items-center gap-0.5">
                                <input
                                    type="checkbox"
                                    id="orders-on-site"
                                    className="w-3 h-3"
                                    disabled
                                />
                                <label htmlFor="orders-on-site" className="text-[9px] font-medium">
                                    Show Only Orders on Site
                                </label>
                            </div>

                            {/* Advanced Filters */}
                            <div className="flex flex-col">
                                <label htmlFor="advanced-filters" className="text-[9px] font-medium">
                                    Advanced Filters:
                                </label>
                                <textarea
                                    id="advanced-filters"
                                    className="border border-gray-300 rounded p-0.5 text-[9px]"
                                    disabled
                                ></textarea>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex gap-1">
                            <button
                                className="bg-gray-200 border border-gray-400 rounded px-1 py-0.5 text-[9px] hover:bg-gray-300 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Setup Filters
                            </button>
                            <button
                                className="bg-gray-200 border border-gray-400 rounded px-1 py-0.5 text-[9px] hover:bg-gray-300 focus:outline-none disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-not-allowed"
                                disabled
                            >
                                Clear Filters
                            </button>
                        </div>
                    </div>

                    {/* Legend Section */}
                    <div className="border border-gray-300 bg-white p-2 flex flex-col gap-1 select-none">
                        {/* Title */}
                        <h3 className="text-xs font-bold">Legend</h3>

                        {/* Legend Items */}
                        <div className="flex flex-col gap-1">
                            {/* Legend Item */}
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-purple-700 border border-gray-400"></div>
                                <span className="text-[10px]">Inactive Order</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-blue-500 border border-gray-400"></div>
                                <span className="text-[10px]">Truck at Check In Station</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-blue-700 border border-gray-400"></div>
                                <span className="text-[10px]">Waiting for Probing</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-yellow-400 border border-gray-400"></div>
                                <span className="text-[10px]">Truck at Probing Station</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-yellow-300 border border-gray-400"></div>
                                <span className="text-[10px]">Waiting to Dump</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-yellow-500 border border-gray-400"></div>
                                <span className="text-[10px]">Truck at Driveway Station</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-orange-400 border border-gray-400"></div>
                                <span className="text-[10px]">Waiting for Ticket</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-orange-700 border border-gray-400"></div>
                                <span className="text-[10px]">Truck at Ticket Printing Station</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-green-500 border border-gray-400"></div>
                                <span className="text-[10px]">Truck Order Completed</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-red-300 border border-gray-400"></div>
                                <span className="text-[10px]">Truck Order Tentatively Rejected</span>
                            </div>
                            <div className="flex items-center gap-1">
                                <div className="w-3 h-3 bg-red-500 border border-gray-400"></div>
                                <span className="text-[10px]">Truck Order Rejected</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LvTruck;
