import React from 'react';
import Box from '@mui/material/Box';
import qr from "../assets/MobileAppSVG/mobile-app-qr.png";

const MobileAppSVG: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
            }}
        >
            <img
                src={qr}
                alt="LV CONTROL logo"
                style={{
                    width: '100%',
                    height: 'auto', // Maintain aspect ratio
                }}
            />
        </Box>
    );
}

export default MobileAppSVG;
