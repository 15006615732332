import {ActiveOrder} from "../context/PageContext";

export async function createOrUpdateActiveOrder(activeOrder: ActiveOrder) {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-or-update-active-order/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(activeOrder),
        });

        if (!response.ok) {
            throw new Error('Failed to update the active order.');
        }

        return await response.json();
    }
    catch (err: any) {
        console.error('Error updating active order:', err.message || 'An unexpected error occurred.');
    }
}
