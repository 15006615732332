import React from 'react';
import Box from '@mui/material/Box';
import truckOnScale from "../../assets/LvTruck/truck_on_scale.png";

const LogoDisplay: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
            }}
        >
            <img
                src={truckOnScale}
                alt="LV truck on scale logo"
                className="w-32 h-32 object-contain"
                style={{ backgroundColor: 'transparent' }}
            />
        </Box>
    );
}

export default LogoDisplay;
